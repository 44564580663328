<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12 mb-3">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-btn
                  text
                  color="grey"
                  class="rounded-lg"
                  @click="$router.push('/vouchers')"
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>

                <v-spacer />

                <v-btn rounded class="primary" elevation="0">
                  Stock Vouchers
                  </v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <div class="d-none d-lg-block">
                <div class="d-flex justify-space-between mb-2">
                  <div></div>
                  <div class="d-flex justify-space-between">

                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            icon
                            v-on="{ ...tooltip}"
                            class="mr-3 ml-3"
                            @click="openFilter()"
                        >
                            <v-icon>mdi-tune</v-icon>
                        </v-btn>
                        </template>
                        <span>Filter</span>
                    </v-tooltip>

                    <v-text-field
                      v-model="filter.keyword"
                      rounded
                      filled
                      dense
                      style="width: 300px"
                      append-icon="mdi-magnify"
                      single-line
                      hide-details
                      clearable
                      placeholder="Rechercher..."
                      v-on:keyup.enter="fetchData"
                    />
                  </div>
                </div>
                <v-divider />
              </div>
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div v-if="stock.data.length > 0">
                  <v-data-table
                    :items="stock.data"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    :hide-default-header="true"
                    :hide-default-footer="false"
                    item-key="id"
                    show-expand
                    class="elevation-0"
                    :items-per-page="15"
                    :headers="headers"
                  >
                    <template v-slot:header="{}">
                      <th class="text-center font-weight-bold pt-2 pb-2">
                        Prix
                      </th>
                      <th class="text-center font-weight-bold">Quantité Restante</th>
                      <th class="text-center font-weight-bold">Total Resatant</th>
                      <th class="text-center font-weight-bold">Quantité consommée</th>
                      <th class="text-center font-weight-bold">Total consommé</th>
                      <!-- <th></th> -->
                    </template>

                    <template v-slot:item="{ item }">
                      <tr :class="item.number_not_used == 0 ? 'ended' : ''">
                        <td
                          class="
                            font-weight-bold
                            text-center text-no-wrap
                            pink--text
                            darken-2
                          "
                        >
                          {{ CurrencyFormatting(item.amount) }} DZD
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ item.number_not_used }}
                        </td>
                        <td
                          class="
                            font-weight-bold
                            text-center text-no-wrap
                            pink--text
                            darken-2
                          "
                        >
                          {{ CurrencyFormatting(item.amount_not_used) }} DZD
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ item.number_used }}
                        </td>

                        <td
                          class="
                            font-weight-bold
                            text-center text-no-wrap
                            pink--text
                            darken-2
                          "
                        >
                          {{ CurrencyFormatting(item.amount_used) }} DZD
                        </td>
                        <!-- <td>
                          <v-btn
                            @click.prevent="[expand(!isExpanded)]"
                            icon
                            small
                            class="expandBtn"
                          >
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </td> -->
                      </tr>
                    </template>

                    <!-- <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <DetailsVouchers :itemList="item" />
                      </td>
                    </template> -->

                    <template v-slot:body.append>
                      <tr class="primary lighten-4">
                        <td class="font-weight-bold text-center text-no-wrap">
                          Total
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ stock.total.total_count }}
                        </td>
                        <td
                          class="
                            font-weight-bold
                            text-center text-no-wrap
                            pink--text
                            darken-2
                          "
                        >
                          {{ CurrencyFormatting(stock.total.total_amount) }} DZD
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ stock.total.total_used }}
                        </td>
                        <td
                          class="
                            font-weight-bold
                            text-center text-no-wrap
                            pink--text
                            darken-2
                          "
                        >
                          {{ CurrencyFormatting(stock.total.total_amount_used) }} DZD
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <FilterDialog
        @filter="[filterBy($event)]"
        ref="filterDialog"
    />

  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";
import DetailsVouchers from "./DetailsVouchers.vue";

import FilterDialog from "./FilterDialog.vue";

export default {
  components: { FilterDialog },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      isLoading: false,
      stock: {
        data: [],
      },
      filter: {
        keyword: "",
        suppliers: [],
        services: [],

        size: 15
      },
      headers: [
        { text: "Prix", value: "amount" },
        { text: "Quantité", value: "quantity" },
        { text: "Total", value: "total" },
        { text: "Details", value: "" },
      ],
    };
  },
  methods: {
    filterBy(data) {
        console.log(data);
      this.filter.services = data.selectedservices;
      this.filter.suppliers = data.selectedsuppliers;
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      HTTP.post("manager/vouchers/stock", this.filter)
        .then((res) => {
          this.isLoading = false;
          console.log(res);
          this.stock = res.data;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    openFilter(){
        this.$refs.filterDialog.open(this.filter);
    }
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.ended{
  background-color: rgb(250, 219, 219);
}

</style>