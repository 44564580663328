<template>
  <div>
    <v-data-table
      :items="itemList.vouchers"
      :hide-default-header="false"
      :hide-default-footer="false"
      item-key="id"
      class="elevation-0"
      :items-per-page="10"
      :headers="headers"
    >
    <template v-slot:item="{ item }">
      <tr >
            <td class="text-center font-weight-bold">
              {{ item.id }}
            </td>

            <td class="text-center font-weight-bold pink--text darken-2">
              {{ CurrencyFormatting(item.amount) }}
              DZD
            </td>

            <td class="text-center font-weight-bold">
              {{ item.used == 1 ? "Oui" : "Non" }}
            </td>

            <td class="text-center font-weight-bold">
              {{ item.expiration_data }}
            </td>
            <td class="text-center font-weight-bold">
              {{ item.supplier ? item.supplier.name : "-" }}
            </td>

            <td class="text-center font-weight-bold">
              {{ item.service ? item.service.name : "-" }}
            </td>
          </tr>
    </template>
    </v-data-table>

    <!-- <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center font-weight-bold">ID Voucher</th>
            <th class="text-center font-weight-bold">Montant</th>
            <th class="text-center font-weight-bold">Utilisé ?</th>
            <th class="text-center font-weight-bold">Date d'expiration</th>
            <th class="text-center font-weight-bold">Supplier</th>
            <th class="text-center font-weight-bold">Service</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="voucher in item.vouchers" :key="voucher.id">
            <td class="text-center font-weight-bold">
              {{ voucher.id }}
            </td>

            <td class="text-center font-weight-bold pink--text darken-2">
              {{ CurrencyFormatting(voucher.amount) }}
              DZD
            </td>

            <td class="text-center font-weight-bold">
              {{ voucher.used == 1 ? "Oui" : "Non" }}
            </td>

            <td class="text-center font-weight-bold">
              {{ voucher.expiration_data }}
            </td>
            <td class="text-center font-weight-bold">
              {{ voucher.supplier ? voucher.supplier.name : "-" }}
            </td>

            <td class="text-center font-weight-bold">
              {{ voucher.service ? voucher.service.name : "-" }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
  </div>
</template>
  
  <script>
export default {
  props: ["itemList"],
  data() {
    return {
      headers: [
        { text: "ID Voucher", value: "id",align: 'center', },
        { text: "Prix", value: "amount", align: 'center', },
        { text: "Utilisé ?", value: "used" , align: 'center',},
        { text: "Expiration", value: "expiration_data", align: 'center', },
        { text: "Supplier", value: "supplier.name" , align: 'center',},
        { text: "Service", value: "service.name" , align: 'center',},
      ],
    };
  },
  methods: {},
};
</script>